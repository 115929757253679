
import Vue from "vue";
import DiscoverPeopleItem from "@/components/Discover/DiscoverPeopleItem.vue";

export default Vue.extend({
  name: "DiscoverPeopleItems",
  components: {
    DiscoverPeopleItem
  },
  props: { users: { type: Array, default: null, required: false } }
});
