
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "DiscoverPeopleItem",
  props: ["user"],
  data: function() {
    return {
      eyeLoading: false
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  },
  methods: {
    routerLink() {
      if (this.profile?.id) {
        if (this.user.type == "mentee") {
          this.$router.push(`/mentorees/${this.user.uuid}`);
        } else {
          this.$router.push(`/mentors/${this.user.uuid}`);
        }
      } else {
        this.$bvModal.show("login");
      }
    },
    unfollow() {
      if (!this.profile?.id) {
        this.$bvModal.show("login");
        return;
      }
      this.$store
        .dispatch("follow/UNFOLLOW", {
          follower_id: this.user.id,
          type: "followings"
        })
        .then(() => {
          this.$emit("onFollow", {
            id: this.user.id,
            data: false
          });
        });
    },
    follow() {
      if (!this.profile?.id) {
        this.$bvModal.show("login");
        return;
      }
      this.$store
        .dispatch("follow/FOLLOW", {
          follower_id: this.user.id
        })
        .then(() => {
          this.$emit("onFollow", {
            id: this.user.id,
            data: true
          });
        });
    },
    onShowModal() {
      this.eyeLoading = true;

      if (this.user.type === "mentor") {
        this.$store
          .dispatch("mentor/GET_MENTOR", {
            id: this.user.uuid
          })
          .then(response => {
            console.log(response.profile);
            this.$emit("showProfile", response.profile);
            this.eyeLoading = false;
          });
      } else {
        this.$store
          .dispatch("mentee/GET_MENTEE", {
            id: this.user.uuid
          })
          .then(response => {
            this.$emit("showProfile", response.user);
            this.eyeLoading = false;
          });
      }
    },
    showMessages() {
      this.openNewTab({ path: `/conversations/${this.user.uuid}` });
    },
    openNewTab(routeObject: any) {
      const { href } = this.$router.resolve(routeObject);
      window.open(href, "_blank");
    }
  }
});
