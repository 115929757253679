
import Vue from "vue";

export default Vue.extend({
  name: "SearchPeople",
  props: {},
  data() {
    return {
      filter: {
        searchVal: "",
        order_by: ""
      },
      orderOptions: [
        {
          id: "recently-joined",
          name: (this as any).$t(
            "discover.discoverPeopleOrderOptionRecentlyJoined"
          ),
          icon: "time-square"
        },
        {
          id: "most-active",
          name: (this as any).$t(
            "discover.discoverPeopleOrderOptionMostActive"
          ),
          icon: "chart"
        },
        {
          id: "alphabetically",
          name: (this as any).$t(
            "discover.discoverPeopleOrderOptionAlphabetically"
          ),
          icon: "language-line"
        },
        {
          id: "mentorees-only",
          name: (this as any).$t(
            "discover.discoverPeopleOrderOptionMentoreesOnly"
          ),
          icon: "mentoree-check"
        },
        {
          id: "mentors-only",
          name: (this as any).$t(
            "discover.discoverPeopleOrderOptionMentorsOnly"
          ),
          icon: "mentor-check"
        }
      ],
      // Select icons
      OpenIndicator: {
        render: (createElement: any) =>
          createElement("inline-svg", {
            attrs: {
              src: require(`@/assets/icons/arrow.svg`)
            }
          })
      }
    };
  },
  methods: {
    emitSearch() {
      this.$emit("search", this.filter, true);
    },
    clearFilter() {
      this.filter.order_by = "";
      this.emitSearch();
    }
  }
});
