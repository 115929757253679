
import Vue from "vue";
import { mapGetters } from "vuex";
import LoginModal from "@/components/Modals/LoginModal.vue";
import DiscoverPeopleItems from "@/components/Discover/DiscoverPeopleItems.vue";
import UserDetailsModal from "@/components/Modals/UserDetailsModal.vue";
import SearchPeople from "@/components/Discover/SearchPeople.vue";
import DiscoverPeopleItemsLoading from "@/components/Discover/DiscoverPeopleItemsLoading.vue";

export default Vue.extend({
  name: "DiscoverPeoplePage",
  components: {
    DiscoverPeopleItemsLoading,
    SearchPeople,
    DiscoverPeopleItems,
    LoginModal,
    UserDetailsModal
  },
  data: function() {
    return {
      filter: {
        searchVal: "",
        language_id: 1,
        order_by: "followers"
      },
      data: [] as any,
      page: 1,
      per_page: 48,
      last_page: 0,
      load_more: false,
      loading: false,
      userData: {} as any
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  },
  mounted() {
    this.onSearch(1, true);
  },
  methods: {
    onFilter(filter: any) {
      this.filter = filter;
      this.onSearch(1, true);
    },
    onSearch(page: any, isDefault = false) {
      if (this.loading) {
        return;
      }
      if (isDefault) {
        this.data = [];
        this.last_page = 0;
        this.page = 1;
      }

      this.loading = true;
      // Change it for search people not only mentors
      this.$store
        .dispatch("search/SEARCH_PEOPLES", {
          filter: {
            ...this.filter,
            per_page: this.per_page
          },
          page: this.page
        })
        .then(data => {
          this.page = page;
          this.load_more = page < data.last_page;
          this.data.push(...data.data);
          this.loading = false;
        });
    },
    onLoadMore() {
      this.page++;
      this.onSearch(this.page);
    },
    showUser(profile: any) {
      console.log(profile);
      this.userData = profile;
      this.$bvModal.show("user-details");
    },
    onFollow(data: any) {
      const index = this.data.findIndex((e: any) => e.id == data.id);
      if (index > -1) {
        this.data[index].is_following = data.data;

        if (data.data) {
          this.data[index].followers_count++;
        } else {
          this.data[index].followers_count--;
        }
      }
    }
  }
});
